import '../style/page/about.scss'
import Marquee from './components/Marquee';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

export default function about(data, scroll) {
  const marqueePortfolio = new Marquee(data.next.container.querySelector('.marquee-content.portfolio'))
  const resizeObserver = new ResizeObserver(entries => {
    ScrollTrigger.refresh();
  })
  resizeObserver.observe(document.body)

  scroll.on('scroll', (args) => {
    // Get all current elements : args.currentElements
    if (typeof args.currentElements['portfolio'] === 'object') {
      if (marqueePortfolio.timeline.paused()) {
        marqueePortfolio.play()
      }
    } else {
      if (marqueePortfolio.timeline.isActive()) {
        marqueePortfolio.stop()
      }
    }
  });

  setTimeout(() => {
    gsap.timeline({
        scrollTrigger: {
          scroller: scroll != null ? '[data-scroll-container]' : null,
          trigger: '.mission-vision',
          scrub: true,
          pin: true,
          end: '+=200%'
        },
      })
      .fromTo('.mission-vision .mv-heading, .mission-vision .subcaption-holder', {
        y: 100,
        autoAlpha: 0,
      }, {
        y: 0,
        stagger: .5,
        autoAlpha: 1,
      }, 0)
      .to('.mission-vision', {
        y: 1
      })
  }, 500)

  gsap.timeline({
      scrollTrigger: {
        scroller: scroll != null ? '[data-scroll-container]' : null,
        trigger: '.globe-container',
        scrub: true,
        start: "center center",
        end: "bottom+=200% center",
        onEnter: () => window.galaxy.stop(),
        onLeave: () => window.galaxy.play(),
        onEnterBack: () => window.galaxy.stop()
      }
    })
    .from('#earth3d .video', {
      duration: .1
    })
    .to('#earth3d .video', {
      y: "-100%",
      ease: 'power4.in'
    })

  gsap.utils.toArray("#mcsv_earth").forEach(video => videoScrub(video, {
    scrollTrigger: {
      scroller: scroll != null ? '[data-scroll-container]' : null,
      trigger: '#earth3d',
      start: "center center",
      end: "bottom+=200% center",
      scrub: true,
      pin: true,
    }
  }));

  function videoScrub(video, vars) {
    video = gsap.utils.toArray(video)[0]; // in case selector text is fed in.
    let once = (el, event, fn) => {
        let onceFn = function () {
          el.removeEventListener(event, onceFn);
          fn.apply(this, arguments);
        };
        el.addEventListener(event, onceFn);
        return onceFn;
      },
      prepFunc = () => {
        video.play();
        video.pause();
      },
      prep = () => once(document.documentElement, "touchstart", prepFunc),
      src = video.currentSrc || video.src,
      tween = gsap.fromTo(video, {
        currentTime: 0
      }, {
        paused: true,
        immediateRender: false,
        currentTime: video.duration || 1,
        ease: "none",
        ...vars
      }),
      resetTime = () => (tween.vars.currentTime = video.duration || 1) && tween.invalidate();
    prep();
    video.readyState ? resetTime() : once(video, "loadedmetadata", resetTime);
    return tween;
  }
}