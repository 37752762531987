import gsap from "gsap";

// Main nav
export default class Menu {
    constructor(data) {
        this.isNavActive = false
        this.container = data.next.container
        this.nav = document.querySelector('.mv-navbar');
        this.navMenu = document.querySelector('.mv-navbar-menu');
        this.navContainer = document.querySelector('#mv-nav-menu');
        this.menuLists = document.querySelectorAll('.menu-list-item > *')
        this.menuContactLists = document.querySelectorAll('.menu-contact-list > *')
        this.canvasContainer = document.querySelector('#container-3d')
        this.navTL = gsap.timeline({
            paused: true
        });

        this.setNavbarTL()

        this.navMenu.addEventListener('click', this.menuAction.bind(this));
        this.menuLists.forEach(item => {
            item.addEventListener('click', this.menuAction.bind(this));
        })
    }

    setNavbarTL() {
        this.navTL
            .to(this.canvasContainer, {
                zIndex: 9999,
                opacity: .3
            }, 0)
            .fromTo(this.menuLists, {
                y: '100%',
                opacity: 0
            }, {
                y: '0%',
                opacity: 1,
                duration: 1.5,
                ease: 'power4.out',
                stagger: .1,
                delay: .25
            }, 0)
            .fromTo(this.menuContactLists, {
                y: '100%',
                opacity: 0
            }, {
                y: 0,
                opacity: 1,
                duration: 2,
                ease: 'circ.out',
                stagger: .1,
            }, '-=1.5');
    }

    navbarClose() {
        setTimeout(() => {
            this.navMenu.classList.remove('active')
            if (!document.body.classList.contains('dark-theme')) {
                this.nav.classList.remove('dark-theme')
            }
            this.navContainer.classList.remove('active')
        }, (this.navTL.totalDuration() * 200))
    }

    menuAction() {

        this.isNavActive = !this.isNavActive;
        if (this.isNavActive) {
            this.navMenu.classList.add('active')
            this.nav.classList.add('dark')
            this.navContainer.classList.add('active')
            this.navTL.timeScale(2)
            this.navTL.play()
        } else {
            this.navTL.timeScale(3)
            this.navTL.reverse()
            this.navbarClose()
        }
    }
}