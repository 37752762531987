import * as THREE from 'three';
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls';
import {
    GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader';
import {
    DRACOLoader
} from 'three/examples/jsm/loaders/DRACOLoader';

import {
    EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer';
import {
    RenderPass
} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {
    UnrealBloomPass
} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import {
    SAOPass
} from 'three/examples/jsm/postprocessing/SAOPass'

import fragment from '../shader/fragment.glsl'
import vertex from '../shader/vertex.glsl'
import galaxyFragment from '../shader/galaxyFragment.glsl'
import galaxyVertex from '../shader/galaxyVertex.glsl'

import {
    startLoad
} from './Preloaders';

import GUI from 'lil-gui';
import gsap from 'gsap'

export default class Sketch {
    constructor(options) {
        this.scene = new THREE.Scene();

        this.container = options.dom;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.renderer = new THREE.WebGL1Renderer({
            alpha: true,
        });
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0x0d0d17, 1);
        this.renderer.outputEncoding = THREE.sRGBEncoding;

        this.container.appendChild(this.renderer.domElement);

        this.camera = new THREE.PerspectiveCamera(
            60,
            this.width / this.height,
            0.001,
            1000
        )

        this.camera.position.set(0, 0, 5);
        this.helix = null;
        this.control = new OrbitControls(this.camera, this.renderer.domElement);
        this.time = 0;
        this.gltfLoader = new GLTFLoader();
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('/draco/');
        this.gltfLoader.setDRACOLoader(this.dracoLoader);

        //Axis Helper
        // this.axesHelper = new THREE.AxesHelper( 5 );
        // this.scene.add( this.axesHelper );

        this.isPlaying = true;
        this.isMobile = false;

        this.loadGLTFModel('https://res.cloudinary.com/mcsventures/image/upload/v1698686710/dna-02_g6bm2q.glb', './dna-02.glb')
            .then(gltf => {
                // Do something with the loaded GLTF model
                this.geometry = gltf.scene.children[0].geometry
                gltf.scene.children[0].material = this.material
                this.geometry.scale(1, 1, 1)

                this.helixGroup = new THREE.Group()
                this.scene.add(this.helixGroup)

                this.settings();
                this.addObjects();
                this.addParticles();
                // this.addLights();
                this.initPostProcess();
                this.resize();
                this.setupResize();
                this.render();

                //Object center
                this.helix.position.set(0, 0, 0)
                this.helix.matrixWorldNeedsUpdate = true;
                const obj = new THREE.Box3().setFromObject(this.helix);
                const offsetY = -obj.min.y;

                const center = new THREE.Vector3()
                obj.getCenter(center);

                // Reposition the model
                this.helix.position.sub(center)
                this.helix.position.y = offsetY * (this.isMobile ? 5 : 8.5);

                if (this.isMobile) {
                    this.helixGroup.position.x = .5;
                    this.helixGroup.rotation.z = 25 * (Math.PI / 180);
                }
            })
            .then(() => {
                setTimeout(() => {
                    startLoad(options.barba)
                }, 500)
            })
            .catch(error => {
                console.log('Sorry can\'t load the website!')
                // Handle the error if the model fails to load
            });
    }

    loadModel(url) {
        return new Promise((resolve, reject) => {
            this.gltfLoader.load(url, resolve, undefined, reject);
        });
    }

    async loadGLTFModel(url, secondUrl) {
        try {
            const gltf = await this.loadModel(url);
            return gltf;
        } catch (error) {
            try {
                const gltf = await this.loadModel(secondUrl);
            } catch (error) {

                console.error('Failed to load model from both URLs:', error);
                throw error;
            }
        }
    }

    initPostProcess() {
        this.renderScene = new RenderPass(this.scene, this.camera);

        this.bloomPass = new UnrealBloomPass(new THREE.Vector2(this.width, this.height), 1.5, 0.9, 0.85);

        this.saoPass = new SAOPass(this.scene, this.camera, false, true, new THREE.Vector2(this.width, this.height));

        this.saoPass['params']['output'] = SAOPass.OUTPUT.Beauty
        this.saoPass['params']['saoBias'] = this.effectSettings.saoBias
        this.saoPass['params']['saoIntensity'] = this.effectSettings.saoIntensity
        this.saoPass['params']['saoScale'] = this.effectSettings.saoScale
        this.saoPass['params']['saoKernelRadius'] = this.effectSettings.saoKernelRadius
        this.saoPass['params']['saoMinResolution'] = this.effectSettings.saoMinResolution
        this.saoPass['params']['saoBlur'] = this.effectSettings.saoBlur
        this.saoPass['params']['saoBlurRadius'] = this.effectSettings.saoBlurRadius
        this.saoPass['params']['saoBlurStdDev'] = this.effectSettings.saoBlurStdDev
        this.saoPass['params']['saoBlurDepthCutoff'] = this.effectSettings.saoBlurDepthCutoff

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(this.renderScene);
        this.composer.addPass(this.bloomPass);
        this.composer.addPass(this.saoPass);

    }

    settings() {
        let that = this;

        this.effectSettings = {
            progress: 0,
            bloomThreshold: 0.1,
            bloomStrength: 3.4,
            bloomRadius: 0.95,

            saoBias: .5,
            saoIntensity: .18,
            saoScale: 10,
            saoKernelRadius: 100,
            saoMinResolution: 0,
            saoBlur: true,
            saoBlurRadius: 8,
            saoBlurStdDev: 4,
            saoBlurDepthCutoff: .01
        };

        // this.gui = new GUI()
        // this.gui.add(this.effectSettings, 'progress', 0, 1, 0.001);
        // this.gui.add(this.effectSettings, 'bloomThreshold', 0, 10, 0.001);
        // this.gui.add(this.effectSettings, 'bloomStrength', 0, 10, 0.001);
        // this.gui.add(this.effectSettings, 'bloomRadius', 0, 10, 0.001);
    }

    addObjects() {

        this.material = new THREE.ShaderMaterial({
            extensions: {
                derivatives: '#extension GL_OES_standart_deriveatives : enable'
            },
            side: THREE.DoubleSide,
            transparent: true,
            uniforms: {
                time: {
                    value: 0
                },
                uColor1: {
                    value: new THREE.Color(0x6647FF)
                },
                uColor2: {
                    value: new THREE.Color(0x6647FF)
                },
                uColor3: {
                    value: new THREE.Color(0x270F70)
                },
                time: {
                    value: 0
                },
                resolution: {
                    value: new THREE.Vector4()
                },

            },

            vertexShader: vertex,
            fragmentShader: fragment,

            depthTest: false,
            depthWrite: false,
            blending: THREE.AdditiveBlending
        });

        this.number = this.geometry.attributes.position.array.length;

        let randoms = new Float32Array(this.number / 3);
        let colorRandoms = new Float32Array(this.number / 3);

        for (let i = 0; i < this.number / 3; i++) {
            randoms.set([Math.random()], i)
            colorRandoms.set([Math.random()], i);
        }

        this.geometry.setAttribute('randoms', new THREE.BufferAttribute(randoms, 1))
        this.geometry.setAttribute('colorRandoms', new THREE.BufferAttribute(colorRandoms, 1))

        // this.geometry = new THREE.PlaneGeometry(1, 1, 10, 10);

        this.helix = new THREE.Points(this.geometry, this.material);
        this.helixGroup.add(this.helix)
    }

    addParticles() {
        const particesCount = 100
        const positions = new Float32Array(particesCount * 3)
        const colors = new Float32Array(particesCount * 3)
        const insideColor = new THREE.Color('#6647FF')
        const outsideColor = new THREE.Color('#FF7357')

        for (let i = 0; i < particesCount; i++) {
            positions[i * 3 + 0] = (Math.random() - 0.5) * 15
            positions[i * 3 + 1] = ((Math.random() - .5) * 15)
            positions[i * 3 + 2] = (Math.random() - 0.5) * 15

            const mixedColor = insideColor.clone()
            mixedColor.lerp(outsideColor, Math.random())

            colors[i * 3 + 0] = mixedColor.r
            colors[i * 3 + 1] = mixedColor.g
            colors[i * 3 + 2] = mixedColor.b

        }

        this.particlesGeometry = new THREE.BufferGeometry()
        this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))

        //Material
        this.particlesMaterial = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            vertexShader: galaxyVertex,
            fragmentShader: galaxyFragment,
            uniforms: {
                uBlink: {
                    value: 1
                },
                uTime: {
                    value: 200
                },
                uSize: {
                    value: 30 * this.renderer.getPixelRatio()
                }
            }
        })

        //Points
        this.particles = new THREE.Points(this.particlesGeometry, this.particlesMaterial)
        // particles.position.set(0, 0, 0)

        this.scene.add(this.particles)
    }

    mobileVersion(options) {
        this.isMobile = true
        this.camera.lookAt(-1.5, 0, 0);

        if (options.smoothScroll != null) {
            options.smoothScroll.on('scroll', (args) => {
                this.scrollAnimation(args.scroll.y)
            })
        } else {
            window.addEventListener('scroll', e => {
                this.scrollAnimation(window.scrollY)
            })
        }
    }
    scrollAnimation(scrollTop) {
        if (this.helix != null) {
            this.helixGroup.rotation.y = Math.PI * ((scrollTop + 1) / (document.body.offsetHeight / 2))
        }
    }

    setupResize() {
        window.addEventListener('resize', this.resize.bind(this))
    }

    resize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix()

        this.renderer.setSize(this.width, this.height);
        this.composer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }

    addLights() {
        const light1 = new THREE.AmbientLight(0xffffff, 0.5);
        this.scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xffffff, 0.5);
        light2.position.set(0.5, 0, 0.866)
        this.scene.add(light2);
    }

    stop() {
        this.isPlaying = false;
    }

    play() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.render()
        }
    }

    render() {
        if (!this.isPlaying) return;
        this.time += 0.05;
        this.helixGroup.rotation.y = this.time * 0.05
        this.bloomPass.threshold = this.effectSettings.bloomThreshold;
        this.bloomPass.strength = this.effectSettings.bloomStrength;
        this.bloomPass.radius = this.effectSettings.bloomRadius;

        this.particlesMaterial.uniforms.uTime.value = this.time * 0.25

        if (this.isMobile) {
            this.material.uniforms.time.value = this.time
            this.helix.rotation.y = this.time * 0.05
            this.particlesMaterial.uniforms.uTime.value = this.time * 0.25
        } else {
            this.control.update();
        }

        this.renderer.render(this.scene, this.camera)
        this.renderer.clearDepth();
        requestAnimationFrame(this.render.bind(this));

        this.composer.render()
    }
}