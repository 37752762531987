import gsap from "gsap"
import Splitting from "splitting"

export const startLoad = (data) => {
	const q = gsap.utils.selector(data.next.container);
	Splitting();
	const preloaderTL = gsap.timeline()
	preloaderTL
		.to('#preloader', {
			autoAlpha: 0,
			delay: 1,
			ease: 'power3.out',
			duration: 1.5,
			onStart: () => {
				if (!!document.querySelector('#preloader svg')) {

					gsap.to('#preloader svg path', {
						autoAlpha: (e) => gsap.utils.random(.5, 0),
						scale: 0,
						ease: "power4.out",
						duration: 1,
						stagger: {
							each: .01,
							from: "random",
						}
					})
				}
				gsap.to('.mv-navbar', {
					autoAlpha: 1,
					delay: 1
				})
			}
		})
		.fromTo(q('.section-main-title span'), {
			autoAlpha: 0,
			y: '100%',
		}, {
			duration: 2,
			y: 0,
			autoAlpha: (e) => gsap.utils.random(.8, 1),
			ease: "power4.out",
			onStart: () => {
				const subcaption = !!document.querySelector('.subcaption')
				if (subcaption) {
					gsap.to('.subcaption', {
						delay: .5,
						autoAlpha: 1,
						duration: 1,
						ease: "circle.out",
					})
				}
			},
			stagger: {
				each: q('.section-main-title span').length * 0.001,
				from: "random",
			}
		}, '-=2')

	return preloaderTL
}

export const endLoad = (data) => {
	gsap.to('.mv-navbar', {
		autoAlpha: 0
	})

	if (data.next.namespace === 'article') {
		if (data.current.container.classList.contains('dark-theme')) {
			data.current.container.classList.remove('dark-theme')
		}
	} else {
		if (!data.current.container.classList.contains('dark-theme')) {
			data.current.container.classList.add('dark-theme')
		}

	}
	const endLoadTL = gsap.timeline()
	endLoadTL
		.to('#preloader', {
			autoAlpha: 1,
			ease: 'power3.out',
			delay: 1,
			duration: 1
		}, 0)
		.fromTo('.section-main-title span', {
			autoAlpha: 1,
			y: 0,
		}, {
			duration: 1,
			delay: 0,
			y: '100%',
			autoAlpha: 0,
			ease: "power2.in",
			onStart: () => {
				const subcaption = !!document.querySelector('.subcaption')
				if (subcaption) {
					gsap.to('.subcaption', {
						delay: 1,
						autoAlpha: 0,
						duration: 2,
						ease: "circle.out",
					})
				}
			},
			stagger: {
				each: 0.05,
				from: "random",
			}
		}, '-=2')

	return endLoadTL
}