import {gsap} from "gsap";

export default class FullScroll {
  constructor(options){
    this.sections = options.section;
    this.listening = false;
    this.direction = 'down';
    this.activeIndex = 0;
    this.current = 0;
    this.firstLoad = true;
    this.afterRender = options.afterRender ?? null;
    this.onLeave = options.onLeave ?? null;
    this.touch = {
      startX: 0,
      startY: 0,
      dx: 0,
      dy: 0,
      startTime: 0,
      dt: 0
    };
    this.tlDefault = {
      ease: "slow.inOut",
      duration: 1
    };

    gsap.set(this.sections, {visibility: 'hidden', overflow: 'hidden', pointerEvents: 'none'})
    this.init()
    
  }
  init(){
    gsap.set('.section', {position: 'absolute'})
    this.sections.forEach((section, index) => {
      section.dataset.index = index
    });
    gsap.to(this.sections[this.current], {
      visibility: 'visible',
      onStart: ()=>{
        this.callBackAfterRender()
      },
      onComplete: ()=> {
        this.listening = true
        this.firstLoad = false
      }
    })
    this.paginationGenearator()
    this.activePagination()
  }
  
  paginationGenearator(){
    const paginationContainer = document.createElement('div')
    paginationContainer.className = 'fullpage-pagination'
    this.sections.forEach((section)=>{
      const page = document.createElement('button')
      page.className = 'nav-link'
      page.dataset.name = section.dataset.title
      paginationContainer.appendChild(page)

      page.addEventListener('click', this.jumpToSection.bind(this, section.dataset.index))
    })
    const html = this.sections[0].parentNode
    html.appendChild(paginationContainer)
  }

  callBackAfterRender(){
    
    this.setActiveIndex()
    if(this.afterRender){
    return  this.afterRender( {index: this.activeIndex, origin: this.sections[this.activeIndex], direction: this.direction, firstLoad: this.firstLoad}  )
    }
  }

  callBackonLeave(param){
    this.activePagination()
    
    this.onLeave({
      index: this.activeIndex, 
      origin: this.sections[this.activeIndex], 
      destination: this.sections[param.dataset.index], 
      direction: this.direction})
  }

  activePagination(){
    const activeSection = this.sections[0].parentNode.querySelector('.section.active')
    
    const pagenations = this.sections[0].parentNode.querySelectorAll('.fullpage-pagination .nav-link')
    pagenations.forEach((item, index)=>{
      if(activeSection.dataset.title == item.dataset.name){
        item.classList.add('active')
      }else{
        item.classList.remove('active')
      }
    })
  }

  slideNext(current, nextEl){
    const currentTexts = current.querySelectorAll('span')
    const nextTexts = nextEl.querySelectorAll('span')
    const isCurrentBtn = !!current.querySelector('.btn')
    const isNextBtn = !!nextEl.querySelector('.btn')
    let nextBtn, currentBtn
    if(isNextBtn || isCurrentBtn){
      nextBtn = nextEl.querySelector('.btn')
      currentBtn = current.querySelector('.btn')
    }
    
    const tl = gsap.timeline({
      defaults: this.tlDefault,
      onStart: ()=> {
        current.classList.remove('active')
        nextEl.classList.add('active')
        this.callBackonLeave(nextEl)
      },
      onComplete: ()=> {
        this.listening = true
      }
    })

    tl.to(nextEl, {visibility: 'visible', onComplete: ()=>this.callBackAfterRender() },0)
    tl.to(currentTexts, { autoAlpha: 0, ease: "power2.inOut", stagger: {
      each: 0.005,
      ease: 'power2.inOut',
      from: "end",
      },
    },0)
    tl.fromTo(currentBtn, { autoAlpha: 1, ease: "power2.inOut",},{ autoAlpha: 0, }, 0)
    tl.to(current, {visibility: 'hidden', duration: 0})
    tl.fromTo(nextTexts, { autoAlpha: 0, ease: "power2.inOut",},{ autoAlpha: 1, stagger: {
        each: 0.005,
        ease: 'power2.inOut',
        from: "start",
      }
    })
    tl.fromTo(nextBtn, { autoAlpha: 0, ease: "power2.inOut",},{x:0, autoAlpha: 1, }, '>-100%')
    
  }
  
  slidePrev(current, prevEl){
    const currentTexts = current.querySelectorAll('span')
    const prevTexts = prevEl.querySelectorAll('span')
    const isbtn = !!prevEl.querySelector('.btn')
    let prevBtn, currentBtn
    if(isbtn){
      prevBtn = prevEl.querySelector('.btn')
      currentBtn = current.querySelector('.btn')
    }
    
    const tl = gsap.timeline({
      defaults: this.tlDefault,
      onStart: ()=>{
        current.classList.remove('active')
        prevEl.classList.add('active')
        this.callBackonLeave(prevEl)
      },
      onComplete: ()=> {
        this.listening = true
      }
    })
    tl.to(prevEl, {visibility: 'visible', onComplete: ()=>this.callBackAfterRender()},0)
    tl.to(currentTexts, { autoAlpha: 0, duration: .5, ease: "power2.inOut", stagger: {
        each: -0.0025,
        from: "end",
        }
      }, 0)
    tl.fromTo(currentBtn, { autoAlpha: 1, ease: "power2.inOut",},{ autoAlpha: 0, }, 0)
    tl.to(current, {visibility: 'hidden', duration: 0})
    tl.fromTo(prevTexts, { autoAlpha: 0,  ease: "power2.inOut",},{ autoAlpha: 1, stagger: {
        each: 0.005,
        ease: 'power2.inOut',
        from: "start",
      }
    })
    tl.fromTo(prevBtn, { autoAlpha: 0, ease: "power2.inOut",},{x:0, autoAlpha: 1, }, '>-100%')
  }

  jumpToSection(nextsection){

    const current = this.sections[this.activeIndex]
    const nextEl = this.sections[nextsection]
    this.direction = nextEl < current ? "down" : "up";

    this.slideNext(current, nextEl)
    this.listening = false;
  }

  handleWheel(e){
    if (this.listening){
      if(e.wheelDeltaY > 50 || e.wheelDeltaY < -50){
        this.direction = e.wheelDeltaY < 0 ? "down" : "up";
        this.handleDirecton()
      }
    }
  }

  setActiveIndex(){
    const active = document.querySelector('section.active')
    const sections = [...this.sections]
    this.activeIndex = sections.indexOf(active)
  }
  
  handleDirecton(){
    this.setActiveIndex()
    const current = this.sections[this.activeIndex]
    if(this.listening && this.direction === "down"){
      
      if(this.activeIndex < (this.sections.length - 1)){
        
        const nextEl = current.nextElementSibling;
        this.slideNext(current, nextEl)
        this.listening = false;
      };
    }
    
    if(this.listening && this.direction === "up"){

      if(this.activeIndex > 0){
        const prevtEl = current.previousElementSibling;
        this.slidePrev(current, prevtEl)
        this.listening = false;
      };
    }
    
  }

  handleTouchStart(e) {
    if (!this.listening) return;
    const t = e.changedTouches[0];
    this.touch.startX = t.pageX;
    this.touch.startY = t.pageY;
  }
  
  handleTouchMove(e) {
    if (!this.listening) return;
    e.preventDefault();
  }
  
  handleTouchEnd(e) {
    if (!this.listening) return;
    const t = e.changedTouches[0];
    this.touch.dx = t.pageX - this.touch.startX;
    this.touch.dy = t.pageY - this.touch.startY;
    if (this.touch.dy > 10) this.direction = "up";
    if (this.touch.dy < -10) this.direction = "down";
    this.handleDirecton();
  }

}