
const menuData = [
	{
		id: 0,
		name: 'Home',
		link: '/',
		isActive: false
	},
	{
		id: 1,
		name: 'About us',
		link: '/about',
		isActive: false
	},
	{
		id: 2,
		name: 'Team',
		link: '/team',
		isActive: false
	},
	{
		id: 3,
		name: 'Portfolio',
		link: '/portfolio',
		isActive: false
	},
	{
		id: 4,
		name: 'Mstars',
		link: '/mstars',
		isActive: false
	},
	{
		id: 5,
		name: 'News',
		link: '/news',
		isActive: false
	}
]

export default function generateMenu(data){
    for(let i = 0; i < menuData.length; i++){
		const li = document.createElement('li')
		const a = document.createElement('a')
		const link = document.createAttribute('href')
		a.className = "nav-link"
		li.setAttribute('data-lang', 'en')
		a.textContent = menuData[i].name
		a.setAttributeNode(link)
		a.href = menuData[i].link
		li.appendChild(a)
		
		if(window.location.pathname.split("/")[1] == menuData[i].link){
			menuData[i].isActive = true
		}
		(menuData[i].isActive ? li.className = 'active' : null)
	
		document.querySelector(".menu-list-item").innerHTML += li.outerHTML
    }
}
