import gsap from "gsap";
import Sketch from "./components/Sketch";
import Marquee from "./components/Marquee";
import FullScroll from "./components/FullpageScroll";

export default class mainPage {
	constructor(data) {
		// this.cursor = new Cursor(data)
		// this.tlFund = gsap.timeline({paused: true})
		this.tlArticle = gsap.timeline({
			paused: true
		})
		this.tlContact = gsap.timeline({
			paused: true
		})
		this.tlTeam = gsap.timeline({
			paused: true
		})
		this.tlPortfolio = gsap.timeline({
			paused: true
		})
		this.Helix = new Sketch({
			dom: data.next.container.querySelector('#container-3d'),
			barba: data
		})
		// this.Helix.stop()

		const marqueeTeam = new Marquee(data.next.container.querySelector('.marquee-content.team'))
		const marqueePortfolio = new Marquee(data.next.container.querySelector('.marquee-content.portfolio'))
		const cursor = this.cursor
		const Helix = this.Helix
		const tlFund = this.tlFund
		const tlArticle = this.tlArticle
		const tlContact = this.tlContact
		const tlTeam = this.tlTeam
		const tlPortfolio = this.tlPortfolio

		// this.tlFund
		// .from('.counter', {textContent:0, opacity:0, ease: 'power1.out', snap: { textContent: 2 }, duration:2, stagger: .25,
		// onComplete: function(){
		// 	this.targets().forEach(element => {
		// 		element.innerHTML += '+'
		// 	});
		// 	}
		// }, 0)
		// .from('.counter-cirlce circle', {strokeDashoffset: 1000, ease: 'power1.out', duration:2,  stagger: .25}, 0);

		// Article section timeline
		this.tlArticle
			.fromTo('.section-article .mv-article', {
				autoAlpha: 0,
				x: '150%',
				duration: 2,
				ease: 'power4.out'
			}, {
				autoAlpha: 1,
				x: '0%',
				duration: 2,
				ease: 'power4.out'
			}, 0);

		// Article section timeline
		this.tlTeam
			.fromTo('.marquee-content.team .slider:nth-child(1)', {
				autoAlpha: 0,
				y: '-50%',
				duration: 2,
				ease: 'power4.out'
			}, {
				autoAlpha: 1,
				y: '0%',
				duration: 2,
				ease: 'power4.out'
			}, 0)
			.fromTo('.marquee-content.team .slider:nth-child(2)', {
				autoAlpha: 0,
				y: '50%',
				duration: 2,
				ease: 'power4.out'
			}, {
				autoAlpha: 1,
				y: '0%',
				duration: 2,
				ease: 'power4.out'
			}, 0);

		// Article section timeline
		this.tlPortfolio
			.fromTo('.marquee-content.portfolio .slider:nth-child(1)', {
				autoAlpha: 0,
				y: '-50%',
				duration: 2,
				ease: 'power4.out'
			}, {
				autoAlpha: 1,
				y: '0%',
				duration: 2,
				ease: 'power4.out'
			}, 0)
			.fromTo('.marquee-content.portfolio .slider:nth-child(2)', {
				autoAlpha: 0,
				y: '50%',
				duration: 2,
				ease: 'power4.out'
			}, {
				autoAlpha: 1,
				y: '0%',
				duration: 2,
				ease: 'power4.out'
			}, 0);

		// Contact section timeline 
		this.tlContact
			.fromTo('#contact-us .stagger-appear', {
				opacity: 0
			}, {
				opacity: 1,
				duration: 2,
				ease: 'ease.out',
				stagger: .1
			}, "-=1.5");

		this.fullScroll = new FullScroll({
			section: data.next.container.querySelectorAll('section'),
			afterRender: function ({
				index,
				origin,
				direction,
				firstLoad
			}) {
				if (origin.dataset.anchor === 'about') {

					if (firstLoad) {
						setTimeout(() => {
							if (Helix.helix != null) {
								gsap.from(Helix.helixGroup.rotation, {
									x: 45 * (Math.PI / 180),
									y: -180 * (Math.PI / 180),
									ease: "power2.out",
									duration: 3,
									delay: 1
								})
							}
						}, 200)
					}
				} else if (origin.dataset.anchor === 'team') {
					marqueeTeam.play()
					tlTeam.timeScale(1).play()
				} else if (origin.dataset.anchor === 'portfolio') {
					marqueePortfolio.play()
					tlPortfolio.timeScale(1).play()
				} else if (origin.dataset.anchor === 'project') {

				} else if (origin.dataset.anchor === 'article') {
					tlArticle.timeScale(1).play();
				} else if (origin.dataset.anchor === 'contact') {
					tlContact.timeScale(1).delay(1).play();
				}
			},

			onLeave: function ({
				origin,
				destination,
				direction
			}) {

				// cursor.stamp.classList.remove('active')
				// cursor.cursor.classList.remove('active')

				const destIndex = destination.dataset.index;
				const delta = (destIndex * -90) * (Math.PI / 180)

				if (Helix.helix != null) {
					switch (+destIndex) {
						default:
							gsap.to(Helix.camera.position, {
								x: delta * 0.5,
								ease: 'power1.inOut',
								duration: 1.5
							});
							gsap.to(Helix.helixGroup.position, {
								y: -delta,
								ease: 'power1.inOut',
								duration: 1.5
							});
							gsap.to(Helix.helixGroup.rotation, {
								y: -delta,
								ease: 'power1.inOut',
								duration: 1.5
							});
							break;
					}
				}

				if (origin.dataset.anchor === 'about') {
					// Do something
				} else if (origin.dataset.anchor === 'team') {
					marqueeTeam.stop()
					tlTeam.timeScale(2).reverse()
				} else if (origin.dataset.anchor === 'portfolio') {
					marqueePortfolio.stop()
					tlPortfolio.timeScale(2).reverse()
				} else if (origin.dataset.anchor === 'article') {
					tlArticle.timeScale(2).reverse()
				} else if (origin.dataset.anchor === 'contact') {
					tlContact.timeScale(2).reverse()
				}
			}
		})
	}


}