export async function getJSON(url){
    try {
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        throw error
    }
}

export async function getData(url){
    const dataJSON = await getJSON(url);
    const datas = [dataJSON.data].map(async (data)=> {
        return {data}
    })
    
    return Promise.all(datas)
}

export async function getArticle(){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
        method: "get",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@mcsventures", requestOptions);
        return await response.json();
    } catch (error) {
        throw error
    }
}

export async function getDatas(url){
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
        method: "get",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        throw error
    }
}