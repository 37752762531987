//Custom style
import './style/style.scss'

import barba from "@barba/core"
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger)
import ScrollTrigger from "gsap/ScrollTrigger";
import MobileDetect from 'mobile-detect'

import './style/libraries/swiper.css'
import Swiper, {
  Navigation,
  Pagination,
  Autoplay
} from 'swiper';

//pages
import mainPage from "./js/mainpage";
import about from "./js/about";
import project from "./js/project";

import Menu from "./js/components/Menu";
import generateMenu from "./js/components/Navbar";
import Stars from "./js/components/Stars";
import Sketch from "./js/components/Sketch";
import Language from "./js/components/Language";
import Marquee from './js/components/Marquee';

import LocomotiveScroll from 'locomotive-scroll'
import {
  startLoad,
  endLoad
} from "./js/components/Preloaders";

import {
  generateTeam,
  generateTeam2Home,
  generatePortfolio2Home,
  generateArticle,
  generateArticlePage,
  generatePortfolio
} from "./js/generate/generateData";
import {
  getDatas,
  getArticle
} from "./js/components/GetData";
import {
  getTeams,
  getPortfolio
} from "./js/lib/sanity";

window.galaxy = null
let homeFullscroll = null
let dna = null
let scroll = null;
const md = new MobileDetect(window.navigator.userAgent)

// disable automatic page view
gtag('config', 'G-2QP9E89L6D', {
  send_page_view: false,
});

barba.hooks.before(() => {
  if (scroll != null) {
    scroll.scrollTo(0, 0);
  }
});
barba.hooks.after((data) => {
  if (scroll != null) {
    scroll.update();
  } else {
    window.scrollTo(0, 0);
  }
  mainBtnInit(data)

  gtag('event', 'page_view', {
    'page_title': document.title,
    'page_location': location.href,
    'page_path': location.pathname,
  });
});

function wheelListener(e) {
  homeFullscroll.fullScroll.handleWheel(e)
}

function handleTouchStart(e) {
  homeFullscroll.fullScroll.handleTouchStart(e)
}

function handleTouchMove(e) {
  homeFullscroll.fullScroll.handleTouchMove(e)
}

function handleTouchEnd(e) {
  homeFullscroll.fullScroll.handleTouchEnd(e)
}

function homeActivate(data) {
  homeFullscroll = new mainPage(data)

  document.addEventListener("wheel", wheelListener);
  document.addEventListener("touchstart", handleTouchStart);
  document.addEventListener("touchmove", handleTouchMove);
  document.addEventListener("touchend", handleTouchEnd);
}

function homeActivateMobile(data) {
  document.body.classList.remove('no-scroll')
  const marqueeTeam = new Marquee(data.next.container.querySelector('.marquee-content.team'))
  const marqueePortfolio = new Marquee(data.next.container.querySelector('.marquee-content.portfolio'))

  marqueeTeam.play()
  marqueePortfolio.play()

  dna = new Sketch({
    dom: data.next.container.querySelector('#container-3d'),
    barba: data
  })
  dna.mobileVersion({
    smoothScroll: scroll
  })
}

function pageInit(data) {
  generateMenu(data);
  const menu = new Menu(data)
}

barba.init({
  debug: false,
  cacheIgnore: false,
  transitions: [{
    name: 'opacity-transition',
    once: (data) => {

      if (md.mobile() == null) {
        smooth()
      }
      pageInit(data)
      mainBtnInit(data)

      if (data.next.container.classList.contains('dark-theme')) {
        document.body.classList.add('dark-theme')
      } else {
        document.body.classList.remove('dark-theme')
      }

      data.next.namespace !== 'home' ? startLoad(data) : ''

      if (data.next.namespace !== 'home' && data.next.namespace !== 'article' && data.next.namespace !== 'team') {
        window.galaxy = new Stars({
          dom: document.body.querySelector('#container-3d'),
          smoothScroll: scroll
        })
        if (data.next.namespace !== 'about') {
          window.galaxy.play()
        }
        data.next.container.querySelector('#container-3d').appendChild(window.galaxy.renderer.domElement)
      } else {
        window.galaxy = null
      }

      data.next.namespace === 'home' ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')

      if (data.next.namespace === 'home') {
        if (md.mobile() == null) {
          homeActivate(data)
        } else {
          homeActivateMobile(data)
        }
        genHomeArticle(data)
        printPortfolioCompanies(data);
        printTeamMembers(data);

      } else if (data.next.namespace === 'about') {
        document.body.classList.remove('no-scroll')
        about(data, scroll, md.mobile() != null)
        printPortfolioCompanies(data);
      } else if (data.next.namespace === 'portfolio') {
        document.body.classList.remove('no-scroll')

        portfolio(data)
      } else if (data.next.namespace === 'article') {
        document.body.classList.remove('no-scroll')
        getArticle()
          .then(result => {
            generateArticlePage(result, data)
          })
          .catch((err) => console.log(err))
      } else if (data.next.namespace === 'project') {
        document.body.classList.remove('no-scroll')
        project(data, scroll)
      } else if (data.next.namespace === 'team') {
        document.body.classList.remove('no-scroll')
        teamPage(data)
      }
    },

    async leave(data) {
      await endLoad(data)
    },

    enter(data) {
      data.next.namespace !== 'home' ? startLoad(data) : ''
      data.next.namespace === 'home' ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll')

      if (data.next.container.classList.contains('dark-theme')) {
        document.body.classList.add('dark-theme')
      } else {
        document.body.classList.remove('dark-theme')
      }

      if (data.current.namespace === 'home') {
        document.removeEventListener("wheel", wheelListener);
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
        if (md.mobile() == null) {
          homeFullscroll.Helix.stop()
        } else {
          dna.stop()
        }

      }

      if (data.next.namespace === 'home' || data.next.namespace === 'team' || data.next.namespace === 'article') {
        if (window.galaxy != null) {
          window.galaxy.stop()
        }
      } else {
        if (window.galaxy != null) {
          if (data.next.namespace !== 'about') {
            window.galaxy.play()
          }
          data.next.container.querySelector('#container-3d').appendChild(window.galaxy.renderer.domElement)
        } else {
          window.galaxy = new Stars({
            dom: document.body.querySelector('#container-3d'),
            smoothScroll: scroll
          })
          if (data.next.namespace !== 'about') {
            window.galaxy.play()
          }
          data.next.container.querySelector('#container-3d').appendChild(window.galaxy.renderer.domElement)
        }
      }

      if (data.next.namespace === 'home') {

        if (md.mobile() == null) {
          homeActivate(data)
        } else {
          homeActivateMobile(data)
        }
        genHomeArticle(data)
        printPortfolioCompanies(data);
        printTeamMembers(data);

        if (window.galaxy != null) {
          window.galaxy.stop()
        }
      } else if (data.next.namespace === 'about') {
        about(data, scroll)
        printPortfolioCompanies(data);
      } else if (data.next.namespace === 'portfolio') {
        portfolio(data)
      } else if (data.next.namespace === 'article') {
        getArticle()
          .then(result => {
            generateArticlePage(result, data)
          })
          .catch((err) => console.log(err))
      } else if (data.next.namespace === 'project') {
        project(data, scroll)
      } else if (data.next.namespace === 'team') {
        document.body.classList.remove('no-scroll')
        teamPage(data)

      }
    },
  }]
});

function smooth() {
  scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    lerp: 0.1
  });

  scroll.on("scroll", ScrollTrigger.update);

  ScrollTrigger.scrollerProxy('[data-scroll-container]', {
    scrollTop(value) {
      return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y;
    },
    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight
      };
    },

    pinType: document.querySelector('[data-scroll-container]').style.transform ? "transform" : "fixed"
  });

  new ResizeObserver(() => {
    scroll.update()
    ScrollTrigger.addEventListener("refresh", () => scroll.update());
    ScrollTrigger.refresh();
  }).observe(document.querySelector("[data-scroll-container]"))
}

function mainBtnInit(data) {
  const isbtn = !!data.next.container.querySelector('.btn-main-outline')

  if (isbtn) {
    const btns = data.next.container.querySelectorAll('.btn-main-outline')

    btns.forEach(btn => {
      const svg = btn.querySelector('svg')
      svg.style.setProperty('--width', btn.clientWidth + 'px');
      svg.style.setProperty('--height', btn.clientHeight + 'px');
    })
  }
}

async function printTeamMembers(data) {
  try {
    const teams = await getTeams();
    generateTeam2Home(teams, data)
  } catch (error) {
    console.log(error)
  }
}

async function printPortfolioCompanies(data) {
  try {
    const portfolio = await getPortfolio();
    generatePortfolio2Home(portfolio, data)

    return portfolio
  } catch (error) {
    console.log(error)
  }
  try {} catch (error) {
    console.log(error)
  }
}

async function portfolio(data) {
  try {
    const portfolio = await getPortfolio();
    generatePortfolio(portfolio, data)

  } catch (error) {
    console.log(error)
  }
}

async function teamPage(data) {
  try {
    const teams = await getTeams();
    await generateTeam(teams, data)
    const images = data.next.container.querySelectorAll('.mv-team-image')
    if (images.length) {
      images.forEach((image) => {
        gsap.from(image.children[0], {
          scale: 1.5,
          opacity: 0,
          duration: 1.5,
          ease: 'power2.out',
          transformOrigin: 'top center',
          scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: image,
            start: 'top bottom-=20%',
          }
        })
      })
    }
  } catch (error) {
    console.log(error)
  }
}

function genHomeArticle(data) {
  getArticle()
    .then(datas => generateArticle(datas, data))
    .then(() => {
      const articleContainer = data.next.container.querySelector('.mv-article')
      //Init Article slider
      const articleSwiper = new Swiper(articleContainer, {
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: {
              delay: 3000,
            },
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
            autoplay: false
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
        grabCursor: true,
        navigation: {
          nextEl: ".section-article .swiper-button-next",
          prevEl: ".section-article .swiper-button-prev",
        },
        modules: [Navigation, Pagination, Autoplay],
      });
    })
}