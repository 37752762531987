import gsap from "gsap";

export default class Marquee {
    constructor(selector) {
        this.marquee = selector
        this.selector = gsap.utils.selector(this.marquee)
        this.marqueeCount = 5
        this.delta = 250
        this.destination = this.delta * this.marqueeCount
        this.isPlay = true;
        this.isReady = false;
        this.timeline = gsap.timeline({
            paused: true,
            repeat: -1
        })
        this.initInterval = setInterval(() => {
            this.selector(".slider img").length > 0 && this.setMarquee();
            this.init()
        }, 100)
    }

    setMarquee() {
        this.isReady = true;
        clearInterval(this.initInterval);

        gsap.set(this.selector(".slider img"), {
            y: (i) => (i * ((this.destination / this.marqueeCount)))
        });
    }

    stop() {
        this.timeline.pause()
    }
    play() {
        this.timeline.play()
    }
    init() {
        this.isReady &&
            this.timeline.to(this.selector(".slider:nth-child(1) img"), {
                duration: 30,
                ease: "none",
                y: `+=${this.destination}`, //move each box 500px to right
                modifiers: {
                    y: gsap.utils.unitize(y => parseFloat(y) % this.destination - this.delta) //force x value to be between 0 and 500 using modulus
                },
            }, 0)
            .to(this.selector(".slider:nth-child(2) img"), {
                duration: 30,
                ease: "none",
                y: `-=${this.destination}`, //move each box 500px to right
                modifiers: {
                    y: gsap.utils.unitize(y => parseFloat(y) % -this.destination - this.delta) //force x value to be between 0 and 500 using modulus
                },
            }, 0);
    }
}