import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass'

import galaxyFragment from '../shader/galaxyFragment.glsl'
import galaxyVertex from '../shader/galaxyVertex.glsl'
export default class Stars {
    constructor(options){
        this.scene = new THREE.Scene();

        this.container = options.dom;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.renderer = new THREE.WebGL1Renderer({
            alpha: true,
        });
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize(this.width, this.height);
        this.renderer.setClearColor(0x01010e, 1);
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.domElement.className = 'staryumaa'
        // this.container.appendChild(this.renderer.domElement);

        this.camera = new THREE.PerspectiveCamera(
            90,
            this.width / this.height,
            0.001,
            1000
        )

        this.camera.position.set(0, 0, 2);
        this.helix = null;
        // this.control = new OrbitControls(this.camera, this.renderer.domElement);
        this.time = 0;
        this.gltfLoader = new GLTFLoader();
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath('/draco/');
        this.gltfLoader.setDRACOLoader(this.dracoLoader);

        this.isPlaying = false;
        
        this.settings();
        this.addParticles();
        this.addLights();
        this.initPostProcess();
        this.resize();
        this.setupResize();
        this.render();
        if(options.smoothScroll != null){
            options.smoothScroll.on('scroll', (args)=>{
                this.scrollAnimation(args.scroll.y)
            })
        }else{
            window.addEventListener('scroll', e=>{
                this.scrollAnimation(window.scrollY)
            })
        }
    }

    scrollAnimation(scrollTop){
        this.camera.position.set(0, Math.PI * ((scrollTop + 1) / (document.body.offsetHeight / 4)), 0)
    }

        

    initPostProcess(){
        this.renderScene = new RenderPass( this.scene, this.camera );

        this.bloomPass = new UnrealBloomPass( new THREE.Vector2( this.width, this.height ), 1.5, 0.9, 0.85 );

        this.saoPass = new SAOPass( this.scene, this.camera, false, true, new THREE.Vector2(this.width, this.height ) );

        this.saoPass['params']['output'] = SAOPass.OUTPUT.Beauty
        this.saoPass['params']['saoBias'] = this.effectSettings.saoBias
        this.saoPass['params']['saoIntensity'] = this.effectSettings.saoIntensity
        this.saoPass['params']['saoScale'] = this.effectSettings.saoScale
        this.saoPass['params']['saoKernelRadius'] = this.effectSettings.saoKernelRadius
        this.saoPass['params']['saoMinResolution'] = this.effectSettings.saoMinResolution
        this.saoPass['params']['saoBlur'] = this.effectSettings.saoBlur
        this.saoPass['params']['saoBlurRadius'] = this.effectSettings.saoBlurRadius
        this.saoPass['params']['saoBlurStdDev'] = this.effectSettings.saoBlurStdDev
        this.saoPass['params']['saoBlurDepthCutoff'] = this.effectSettings.saoBlurDepthCutoff

        this.composer = new EffectComposer( this.renderer );
        this.composer.addPass( this.renderScene );
        this.composer.addPass( this.bloomPass );
        this.composer.addPass( this.saoPass );

    }

    settings(){
        let that = this;

        this.effectSettings = {
            progress: 0,
            bloomThreshold: 0.1,
			bloomStrength: 3.4,
			bloomRadius: 0.95,

            saoBias: .5,
            saoIntensity: .18,
            saoScale: 10,
            saoKernelRadius: 100,
            saoMinResolution: 0,
            saoBlur: true,
            saoBlurRadius: 8,
            saoBlurStdDev: 4,
            saoBlurDepthCutoff: .01
        };

        // this.gui = new GUI()
        // this.gui.add(this.effectSettings, 'progress', 0, 1, 0.001);
        // this.gui.add(this.effectSettings, 'bloomThreshold', 0, 10, 0.001);
        // this.gui.add(this.effectSettings, 'bloomStrength', 0, 10, 0.001);
        // this.gui.add(this.effectSettings, 'bloomRadius', 0, 10, 0.001);
    }

    addParticles(){
        const particesCount = 300
        const positions = new Float32Array(particesCount * 3)
        const colors = new Float32Array(particesCount * 3)
        const insideColor = new THREE.Color('#6647FF')
        const outsideColor = new THREE.Color('#FF7357')

        for(let i = 0; i < particesCount; i++){
            positions[i * 3 + 0] = (Math.random() - 0.5) * 20
            positions[i * 3 + 1] = ((Math.random() - .5) * 20) 
            positions[i * 3 + 2] = (Math.random() - 0.5) * 20
            
            const mixedColor = insideColor.clone()
            mixedColor.lerp(outsideColor, Math.random())
    
            colors[i * 3 + 0] = mixedColor.r
            colors[i * 3 + 1] = mixedColor.g
            colors[i * 3 + 2] = mixedColor.b
        
        }
        
        this.particlesGeometry = new THREE.BufferGeometry()
        this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        
        //Material
        this.particlesMaterial = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            vertexShader: galaxyVertex,
            fragmentShader:  galaxyFragment,
            uniforms:{
                uBlink: { value: 1 },
                uTime: { value: 200 },
                uSize: {value: 30 * this.renderer.getPixelRatio() }
            }
        })
        
        //Points
        this.particles = new THREE.Points(this.particlesGeometry, this.particlesMaterial)
        // particles.position.set(0, 0, 0)
        
        this.scene.add(this.particles)  
    }

    setupResize(){
        window.addEventListener('resize', this.resize.bind(this))
    }

    resize(){
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix()

        this.renderer.setSize(this.width, this.height);
        this.composer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }

    addLights(){
        const light1 = new THREE.AmbientLight(0xffffff, 0.5);
        this.scene.add(light1);
        
        const light2 = new THREE.DirectionalLight(0xffffff, 0.5);
        light2.position.set(0.5, 0, 0.866)
        this.scene.add(light2);

    }

    stop(){
        this.isPlaying = false;
    }

    play(){
        if(!this.isPlaying){
            this.isPlaying = true;
            this.render()
        }
    }

    render(){
        if(!this.isPlaying) return;
        // console.log('render')
        this.time += 0.09;

        this.camera.lookAt(0, 0, 0);

        this.bloomPass.threshold = this.effectSettings.bloomThreshold;
        this.bloomPass.strength = this.effectSettings.bloomStrength;
        this.bloomPass.radius = this.effectSettings.bloomRadius;

        this.particlesMaterial.uniforms.uTime.value = this.time * 0.25

        this.renderer.render(this.scene, this.camera)
        this.renderer.clearDepth(); 
        requestAnimationFrame(this.render.bind(this));

        this.composer.render()
    }
}