import {createClient} from '@sanity/client'
// Import using ESM URL imports in environments that supports it:
// import {createClient} from 'https://esm.sh/@sanity/client'

export const client = createClient({
  projectId: '31bi34bv',
  dataset: 'production',
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: 'v2021-10-21', // use current date (YYYY-MM-DD) to target the latest API version
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

// uses GROQ to query content: https://www.sanity.io/docs/groq
export async function getTeams() {
  const posts = await client.fetch('*[_type == "team"]{_createdAt, fullName, position, hierarchy, social, "image": portrait.asset->url}')
  return posts
}
export async function getPortfolio() {
  const posts = await client.fetch('*[_type == "portfolio"]{_createdAt, company, related, brief, hierarchy, website, "logo": logo.asset->url}')
  return posts
}

export async function createPost(post) {
  const result = client.create(post)
  return result
}

export async function updateDocumentTitle(_id, title) {
  const result = client.patch(_id).set({title})
  return result
}