//Generate detail page
export function generateHomeTeam(datas) {
	// console.log(datas)
	datas[0].data.sort(function (a, b) {
		return a.attributes.hierarchy - b.attributes.hierarchy;
	});

	datas[0].data.slice(9)
	datas[0].data.map((team, index) => {
		const container = document.querySelector('.portfolio-mansory-grid-lists');
		container.innerHTML += `
		<div class="portfolio-item ${ (index % 2 ? 'odd': 'even') }">
			<div class="portfolio-item-image">
				<img src="${team.attributes.Image.data.attributes.url}" alt="">
			</div>
			<div class="portfolio-item-info">
				<h3>${team.attributes.Name}</h3>
				<p>${team.attributes.Position}</p>
			</div>
		</div>`
	})
}

export function generateArticle(datas, barba) {
	datas.items.map(data => {
		const articleContainer = barba.next.container.querySelector('.mv-article')
		let srcWithQuotes = data.content.match(/src\=([^\s]*)\s/)[1],
			src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);
		const date = new Date(data.pubDate)
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		};
		const html = `<div class="swiper-slide">
						<article class="mv-article-card">
							<a target="_blank" href="${data.link}">
							<div class="mv-article-img">
								<img src="${src}" alt="mcs ventures, ${data.title}">
							</div>
							<div class="mv-article-data">
								<div class="mv-article-data-date">${date.toLocaleDateString("en-US", options)}</div>
								<div class="mv-article-data-title">${data.title}
								</div>
							</div>
							</a>
						</article>
					</div>`
		articleContainer.querySelector('.swiper-wrapper').innerHTML += html
	})
}

export function generateArticlePage(datas, barba) {
	datas.items.map((data, index) => {
		const articleContainer = barba.next.container.querySelector('.mv-article')
		let srcWithQuotes = data.content.match(/src\=([^\s]*)\s/)[1],
			src = srcWithQuotes.substring(1, srcWithQuotes.length - 1);
		const date = new Date(data.pubDate)
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric'
		};
		const html = `
			<article class="mv-article-card" >
			  <a  target="_blank" href="${data.link}">
				  <div class="mv-article-img">
					  <img src="${src}" alt="mcs ventures, ${data.title}">
				  </div>
				  <div class="mv-article-data">
					  <div class="mv-article-data-date">${date.toLocaleDateString("en-US", options)}</div>
					  <div class="mv-article-data-title">${data.title}</div>
				  </div>
			  </a>
		  </article>
			`
		articleContainer.innerHTML += html
	})
}

export function generatePortfolio(datas, barba) {
	//filtering
	const mvData = datas.filter(item => item.related === "MCSVentures")
	mvData.sort(function (a, b) {
		return a.hierarchy - b.hierarchy;
	});
	const mhData = datas.filter(item => item.related === "MstarsHub")

	const intelmindData = datas.filter(item => item.related === "Intelmind")

	function htmlEL(partner, index) {
		return `
			<div class="portfolio-partner" data-partner="${index}"> 
				<img src="${partner.logo}" class="partner-logo" alt="mcs ventures, ${partner.company}"> 
				<div class="partner-information">
					<div class="sub-companies"></div>
					<div class="partner-name">${partner.company}</div>
					<p>${partner.brief}</p>
					<a class="btn btn-pill text-md" target="_blank" href="${partner.website}">Visit Website</a>
				</div>
			</div>
			`
	}

	function subCompanyGenerater(datas, index, title) {
		const item = document.querySelector(`[data-partner="${index}"]`)

		const modal = barba.next.container.querySelector(".mv_modal");
		const modalBody = barba.next.container.querySelector(".mv_modal-body");

		document.getElementById('ventures').addEventListener('click', (event) => {
			if (event.target.classList.contains('portfolio-partner')) {
				const partnerIndex = event.target.getAttribute('data-partner');
				if (partnerIndex == index) {
					modal.classList.add('active')
					modalBody.innerHTML = `<h2>${title}</h2>`
					const logoHolder = document.createElement('div')
					logoHolder.className = 'logos'
					datas.map((subpartner, index) => {
						logoHolder.innerHTML += `<img src="${subpartner.logo}" class="subpartner-logo" alt="mcs ventures, ${subpartner.company}"> `
					})
					modalBody.appendChild(logoHolder)
				}

			}
		})
		modal.addEventListener("click", () => {
			if (modal.classList.contains('active')) {
				modal.classList.remove('active')
			}
		})

		item.style.cursor = 'pointer'
		const subCom = item.querySelector('.sub-companies')
		datas.map((subpartner, index) => {
			subCom.innerHTML += `<img src="${subpartner.logo}" class="subpartner-logo" alt="mcs ventures, ${subpartner.company}"> `
		})
	}

	mvData.map(async (partner, index) => {
		const porContainer = barba.next.container.querySelector('#ventures');
		porContainer.innerHTML += htmlEL(partner, index)

		if (partner.company === "Intelmind") {
			subCompanyGenerater(intelmindData, index, 'Intelmind')
		}
		if (partner.company === "Mstars Hub") {
			subCompanyGenerater(mhData, index, 'Mstars Hub')
		}
	})

}


export async function generateTeam(datas, barba) {
	await new Promise(resolve => setTimeout(resolve, 0));

	datas.sort(function (a, b) {
		return a.hierarchy - b.hierarchy;
	});

	datas.map(async (team, index) => {
		const teamContainer = barba.next.container.querySelector('.team-members');
		teamContainer.innerHTML += `
            <div class="mv-team" >
                <div class="mv-team-image">
                    <img src="${team.image}?h=500" alt="mcs ventures, ${team.fullName}">
                </div>
                <div class="mv-team-info">
                    <ul class="social-links">
                        <li><a target="_blank" href="${team.social}"><i class="fab fa-linkedin-in"></i></a> </li>
                    </ul>
                </div>
                <div class="mv-team-infoName">
                    <h3>${team.fullName}</h3>
                    <p>${team.position}</p>
                </div>
            </div>`;
	});
}

export function generateTeam2Home(datas, barba) {
	datas.sort(function (a, b) {
		return a.hierarchy - b.hierarchy;
	});
	const teamContainer = barba.next.container.querySelector('#team-wrapper');
	const slider = teamContainer.querySelectorAll('.team-slider')

	for (let i = 0; i < 12; i++) {
		if (i < 6) {
			slider[0].innerHTML += `<img src="${datas[i].image}?h=300" alt="mcs ventures">`
		} else {
			slider[1].innerHTML += `<img src="${datas[i].image}?h=300" alt="mcs ventures">`
		}
	}
}

export function generatePortfolio2Home(datas, barba) {
	datas.sort(function (a, b) {
		return a.hierarchy - b.hierarchy;
	});
	const teamContainer = barba.next.container.querySelector('.marquee-content.portfolio');
	const slider = teamContainer.querySelectorAll('.logo-slider')

	for (let i = 0; i < 10; i++) {
		if (i < 5) {
			slider[0].innerHTML += `<img src="${datas[i].logo}" alt="mcs ventures, ${datas[i].company}">`
		} else {
			slider[1].innerHTML += `<img src="${datas[i].logo}" alt="mcs ventures, ${datas[i].company}">`
		}
	}
}