import '../../style/components/locomotive.css'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

export default class SmoothScroll{
    constructor(container, scrollTigger = false){
        this.scrollContainer = document.querySelector('[data-scroll-container]');
        this.scrollTigger = scrollTigger;
        this.isPlay = true;
        
        this.locoScroll = new LocomotiveScroll({
          el: this.scrollContainer,
          smooth: true,
          reloadOnContextChange: true,
           smartphone: {
               smooth: false
          }
      });
        
        this.scrollTriggerInit()
    }


    scrollTriggerInit(){
      const smoothScroll = this.locoScroll
      smoothScroll.on("scroll", ()=>ScrollTrigger.update());

      ScrollTrigger.scrollerProxy(this.scrollContainer, {
        scrollTop(value) {
          return arguments.length ? smoothScroll.scrollTo(value, 0, 0) : smoothScroll.scroll.instance.scroll.y;
        }, // we don't have to define a scrollLeft because we're only scrolling vertically.
        getBoundingClientRect() {
          return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        },
        pinType: this.scrollContainer.style.transform ? "transform" : "fixed"
      });
    }
  
    stop(){
      this.isPlaying = false;
      this.locoScroll.destroy()
    }

    play(){
        if(!this.isPlaying){
            this.isPlaying = true;
            this.locoScroll.init()
        }
    }
    
    smoothScrollUpdate(){
      this.locoScroll.update.bind(this)
      ScrollTrigger.addEventListener("refresh", () => this.locoScroll.update.bind(this));
      ScrollTrigger.refresh();
    }
  
  }
  